@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }

  body {
    color: white;
    background: black;
  }
}

.fp-back-button {
  display: none !important;

}

.jodit-status-bar {
  display: none !important;
}

.multi-carousel {
  width: 100%;
  background-color: #A100FF;
  overflow: auto;
  padding: 75px 50px;
}


.multi-carousel::-webkit-scrollbar {
  display: none;
}


.multi-carousel img:hover {
  box-shadow: 1px 1px 5px yellow;

}

.multi-carousel2 {
  width: 100%;
  /* background-color: #A100FF; */
  overflow: auto;
  padding: 75px 50px;
}

.multi-carousel2::-webkit-scrollbar {
  display: none;
}

.multi-carousel2 img:hover {
  /* box-shadow: 1px 1px 5px yellow; */

}


.slider-wrapperss {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  width: 100%;
  margin: auto;
  justify-content: space-around;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.slide1 {
  height: auto;
  width: 22%;
  padding-left: 3rem;
  padding-right: 3rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slide2 {
  height: auto;
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slide-h4 {
  color: white;
  padding: 0;
  /* text-align: center; */
}

.slide-h4 h2 {
  /* padding: 3px; */

  font-size: 20px;


}

.slide1 img {

  width: 100px;
  height: auto;
  border: 1px solid white;
  background-color: white;
  padding: 25px;
}


.slide2 img {

  width: 100px;
  height: auto;
  border-radius: 100px;
  border: 1px solid white;
  background-color: white;
  padding: 25px;
}


.arrows {
  position: relative;
  width: 100%;

  display: flex;
  justify-content: space-between;
  top: 5.5rem;

  margin-top: .5rem;




}

.arrows .arrow {

  font-size: 20px;
  color: rgb(255, 255, 255);
  padding: .5rem 1.1rem;
  border-radius: 50%;
  z-index: 2;
  background-color: rgba(0, 0, 0, .5);

}

.arrows .arrow:hover {
  background: rgba(255, 255, 255, 0.16);
  cursor: pointer;
}


/* Mini Laptop */
.slide1 {
  padding-left: 0;
  padding-right: 0;
}

/* Tab Size */
@media (max-width: 991px) {
  .multi-carousel {
    padding: 50px 25px;
  }

  .multi-carousel2 {
    padding: 50px 25px;
  }

  .slider-wrapperss {
    flex-wrap: wrap;
  }

  .slide1 {
    width: 47%;
  }

  .slider-wrapperss {
    gap: 45px 6%;
  }
}

@media only screen and (max-width: 912px) {
  .slider-wrapperss {
    padding: 0;
    justify-content: center;
  }

  .slide1 {
    /* padding-left: rem;
    padding-right: 1rem; */
  }


  .slide1 img {

    object-fit: cover;
    /* min-width: 100px; */
    height: 100px;
    /* border-radius: 50%; */
    border: 1px solid white;
    background-color: white;

  }

}


@media only screen and (max-width: 450px) {

  .arrows {
    display: none;
  }


  .responsive-slide {
    margin: auto;
    /* display: inline-flex; */
    font-weight: bolder;
    gap: 10px;


  }

  .responsive-slide img {
    width: 70px;
    height: 70px;
    min-height: 70px;
    min-width: 70px;
    /* border-radius: 50%; */
    object-fit: cover;
    border: 1px solid white;
    background-color: white;
  }

  .responsive-div .responsive-slide-h4 {
    color: white;
    padding-top: 10px;
    text-align: center;
    font-size: 12px;
    white-space: nowrap;

  }

  .responsive-div .responsive-slide-h4 h2 {
    /* padding: 3px; */
    margin: 0;
    /* font-size: 16px; */
  }

  .multi-carousel {
    padding: 40px 15px;
  }

  .multi-carousel2 {
    padding: 40px 15px;
  }

}


@media only screen and (max-width: 319px) {

  .responsive-div {

    justify-content: center;
    grid-template-columns: repeat(1, 1fr);


  }



}

.coursepage-footer {
  bottom: 0;
  position: fixed;
  z-index: 1000000000000 !important;
  display: flex;
  /* flex-direction: column-reverse; */
  justify-content:space-around;
  width: 100%;
  align-items: center;
  background-color: rgb(255, 255, 255) !important;
  /* right: 0px; */

}
.watermark-videos {
  width: 80px;
  height: 60px;
  position: absolute;
  opacity: 0.2;
 
}
.watermark-video {
  width: 100px;
  height: 40px;
  position: absolute; 
  opacity: 0.7;
  background-color: #ffffff17;
  border-radius: 10%;

  /* animation: watermarkAnimation 5s linear infinite; */
}

/* @keyframes watermarkAnimation {
  0% {
    opacity: .2;
}
25% {
    opacity: .0;
}
60% {
    opacity: 0;
}
100% {
    opacity: .2;
}
}    */

.custom-dropdown-hauth-menu,
.offcanvas-title-settings,
.notification-heading-padding,
.svgHover,
.notifications-message {
  color:black !important;
}
.offcanvas-title-settings {
  font-weight:600 !important;
}
.offcanvas-body-settings{
  height: calc(100vh - 65px) !important;
  background-color: #fff;
  border-left: 1px solid #d3d3d394;
}
.notifications-unread-dotted {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #00B8D9;
  position: absolute;
  border-radius: 6px;
  top: 6px;
  right: 0px;
}
.offcanvas-backdrop-custom-settings{
  height: 100vh !important;
}
body:has(.offcanvas-notifications-width.show) {
  overflow: hidden;
}
.notifications-counts{
  display: flex !important;
}
.svgHover:hover {
  background-color: #e9ecef !important;
}
.offcanvas-header-settings{
  background-color: #fff;
  border-bottom: 1px solid lightgray;
}
.notification-listing-items .flex-grow-1{
  width: 100%;
}
.fs-14{
  font-size: 14px;
}
.fs-12{
  font-size: 12px;
}
.notification-listing-items .fs-12{
  color: gray;
}
.flex-wrap{
  flex-wrap: wrap !important;
}
html[dir="rtl"] .product-widget-dropdown {
  right: unset !important;
  left: 0;
}
html[dir="rtl"] .product-block {
  border-right: 1px solid var(--card-border-color);
  left: 12px !important;
}

html[dir=rtl] .product-tip {
  
  left: 20px;
}
html[dir=rtl] .offcanvas-end-settings {
  left: 0;
  right: unset;
  transform: translateX(-110%);
}
html[dir=rtl] .offcanvas-settings{
  height: 100vh;
}
html[dir=rtl] .offcanvas-settings.show{
  transform: translateX(0) !important;
}
html[dir=rtl] .notifications-icons-styling{
  margin-right: 0px;
  margin-left: 1rem;
}
html[dir=rtl] .notification-heading-padding{
  padding-right: 0px;
  padding-left: 25px;
}
html[dir=rtl] .notifications-unread-dotted{
  right: unset;
  left: 0px;
}
html[dir=rtl] #offcanvasSettings .settingsCloseStyling{
  right: -19px;
  left: unset;
  top: 10px;
}
html[dir=rtl] #offcanvasSettings .offcanvas-title-settings{
  padding-right: 25px;
  padding-left: 0px;
}